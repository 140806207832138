<template>
    <Introduction :url="'/brochure/accord'" :class="'introduction-accord'">  
        <template #title>
            Accord
        </template>
        <template #speciality>
            языковая школа
        </template>
        <template #min-duration>
            От 20 часов в неделю
        </template>
        <template #cost>
            От 180 евро/неделя
        </template>
        <template #campus>
            Париж
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>