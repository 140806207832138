<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            Accord - языковая школа в 15-м округе Парижа, в нескольких минутах ходьбы от Эйфелевой башни. Учебное заведение предлагает курсы французского языка для взрослых и подростков во Франции, а также летние курсы английского языка для подростков и взрослых в Англии.
            <br>
            Вы можете присоединиться к текущей группе по изучению французского языка каждый понедельник в соответствии со своим уровнем. Школа не берет дополнительные взносы за регистрацию или учебники. 
        </template>
        <template #speciality>
            языковая школа
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="mc">Интенсивный утренний курс</option>
                                <option value="ac">Интенсивный послеобеденный курс</option>
                                <option value="ll">Летний лагерь</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'mc'" id="mc">
                        <template #title>
                            Интенсивный утренний курс
                        </template>
                        <template #description>
                            Интенсивный утренний курс по изучению французского языка для взрослых
                        </template>
                        <template #duree>
                            20 часов в неделю
                        </template>
                        <template #cost>
                            от 300 евро/неделя
                        </template>
                        <template #campus>
                            Париж
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'ac'" id="ac">
                        <template #title>
                            Интенсивный послеобеденный курс
                        </template>
                        <template #description>
                            Интенсивный послеобеденный курс по изучению французского языка для взрослых 
                        </template>
                        <template #duree>
                            20 часов в неделю
                        </template>
                        <template #cost>
                            от 180 евро/неделя
                        </template>
                        <template #campus>
                            Париж
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'll'" id="ll">
                        <template #title>
                            Летний лагерь
                        </template>
                        <template #description>
                            Летний лагерь по изучению французского языка для подростков (12-17 лет)
                        </template>
                        <template #duree>
                            от 1 неделе
                        </template>
                        <template #cost>
                            от 1150 евро/неделя
                        </template>
                        <template #campus>
                            Париж
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>